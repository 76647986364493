import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../utilities/axios";
import { serialize } from "object-to-formdata";
import { getAllPermissions } from "./permission";
const initialState = {
  user: [],
  fetchMe: [],
  oneUser: [],

  subnav: false,
  userId: "",
  oneuserStatus: "idle",
  oneuserError: null,

  fetchMeStatus: "idle",
  fetchMeError: null,

  userSettingsStatus: "idle",
  userSettingsError: null,

  deleteUserStatus: "idle",
  deleteUserError: null,

  adduserStatus: "idle",
  adduserError: null,

  uploadUser: [],
  uploadStatus: "idle",
  uploadError: null,

  affectPermissionGroupeStatus: "idle",
  updateUserStatus: "idle",
  updateUserError: null,
  page: 0,
  rowsPerPage: 5,
  status: "idle",
  error: null,
};

export const affectPermission = createAsyncThunk(
  "users/affectPermission",
  async (body, thunkAPI) => {
    const { permission, id } = body;
    let data;
    try {
      const response = await axios.patch(`/users/affect-permission/${id}`, {
        permission,
      });
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllPermissions());

        return {
          data: data,
          message: "SUCCESS! permissions has been successfully affected.",
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const affectPermissionGroupe = createAsyncThunk(
  "users/affectPermissionGroupe",
  async (body) => {
    const { permissionGroups, id } = body;
    let data;
    try {
      const response = await axios.patch(
        `/users/affect-permission-group/${id}`,
        { permissionGroups }
      );
      data = await response.data;

      if ((response.status = 200)) {
        return {
          data: data,
          message: "SUCCESS! Permissions group has been successfully affected.",
        };
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const fetchUser = createAsyncThunk("users/fetchUser", async () => {
  let data;
  try {
    const response = await axios.get(`/users`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : err);
  }
});

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/users/delete/${id}`);
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchUser());
        return {
          data: data,
          message: "The employee's account has been successfully deleted",
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (body, thunkAPI) => {
    let data;
    const {
      id,
      fullName,
      email,
      phone,
      birthday,
      gender,
      address,
      jobTitle,
      status,
      img,
      hiringDate,
      department,
      role,
      balance,
      leavesInStock,
      authorisationBalance,
    } = body;
    try {
      const response = await axios.patch(
        `/users/${id}`,
        serialize(
          {
            img,
            fullName,
            email,
            phone,
            birthday,
            gender,
            address,
            jobTitle,
            status,
            hiringDate,
            department,
            role,
            balance,
            leavesInStock,
            authorisationBalance,
          },
          { indices: true }
        )
      );
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchUser());
        return {
          data: data,
          message: "SUCCESS! Your account details has been updated.",
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const updateMe = createAsyncThunk(
  "users/updateMe",
  async (body, thunkAPI) => {
    const {
      fullName,
      email,
      phone,
      birthday,
      gender,
      address,
      jobTitle,
      status,
      hiringDate,
      img,
      department,
      role,
      balance,
      authorisationBalance,
    } = body;
    let data;
    try {

      const response = await axios.patch(
        `/users/updateMe`,
        serialize(
          {
            fullName,
            email,
            phone,
            birthday,
            gender,
            address,
            hiringDate,
            img,
            jobTitle,
            status,
            department,
            role,
            balance,
            authorisationBalance,
          },
          { indices: true }
        )
      );

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchMe());

        return {
          data: data,
          message: "SUCCESS! Your account details has been updated.",
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err ? err : err.message);
    }
  }
);

export const fetchMe = createAsyncThunk("users/fetchMe", async () => {
  let data;
  try {
    const response = await axios.get(`/users/me`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : err);
  }
});

export const fetchONeUser = createAsyncThunk(
  "user/fetchONeUser",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`/users/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/users`,
        serialize(body, { indices: true })
      );
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchUser());
        return {
          data,
          message: "The employee's account has been successfully created",
        };
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const uploadUsers = createAsyncThunk("users/upload", async (body) => {
  try {
    const formData = new FormData();
    formData.append("file", body);
    const response = await axios.post("/users/upload", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if ((response.status = 200)) {
      return {
        message: response.data,
      };
    }
  } catch (err) {
    return Promise.reject(err.message ? err.message : err);
  }
});

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    pushUserId: (state, action) => {
      state.userId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setStatus: (state, action) => {
      state.uploadStatus = action.payload;
    },
  },
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload.users;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [uploadUsers.pending]: (state) => {
      state.uploadStatus = "loading";
    },
    [uploadUsers.fulfilled]: (state, action) => {
      state.uploadStatus = "succeeded";
      state.uploadUser = action.payload;
    },
    [uploadUsers.rejected]: (state, action) => {
      state.uploadStatus = "failed";
      state.error = action.payload;
    },

    [fetchMe.pending]: (state) => {
      state.fetchMeStatus = "loading";
    },
    [fetchMe.fulfilled]: (state, action) => {
      state.fetchMeStatus = "succeeded";
      state.fetchMe = action.payload;
    },
    [fetchMe.rejected]: (state, action) => {
      state.fetchMeStatus = "failed";
      state.fetchMeError = action.payload;
    },

    [fetchONeUser.pending]: (state) => {
      state.oneuserStatus = "loading";
    },
    [fetchONeUser.fulfilled]: (state, action) => {
      state.oneuserStatus = "succeeded";
      state.oneUser = action.payload;
    },
    [fetchONeUser.rejected]: (state, action) => {
      state.oneuserStatus = "failed";
      state.oneuserError = action.payload;
    },

    [createUser.pending]: (state) => {
      state.adduserStatus = "loading";
    },

    [createUser.fulfilled]: (state, action) => {
      state.adduserStatus = "succeeded";
    },

    [createUser.rejected]: (state, action) => {
      state.adduserStatus = "failed";
      state.adduserError = action.error.message;
    },

    [updateMe.pending]: (state) => {
      state.userSettingsStatus = "loading";
    },

    [updateMe.fulfilled]: (state) => {
      state.userSettingsStatus = "succeeded";
    },

    [updateMe.rejected]: (state, action) => {
      state.userSettingsStatus = "failed";
      state.userSettingsError = action.error.message;
    },

    [deleteUser.pending]: (state) => {
      state.deleteUserStatus = "loading";
    },

    [deleteUser.fulfilled]: (state) => {
      state.deleteUserStatus = "succeeded";
    },

    [deleteUser.rejected]: (state, action) => {
      state.deleteUserStatus = "failed";
      state.deleteUserStatus = action.error.message;
    },

    [updateUser.pending]: (state) => {
      state.updateUserStatus = "loading";
    },

    [updateUser.fulfilled]: (state) => {
      state.updateUserStatus = "succeeded";
    },

    [updateUser.rejected]: (state, action) => {
      state.updateUserStatus = "failed";
      state.updateUserError = action.error.message;
    },
  },
});
export const {
  setRowsPerPage,
  pushUserId,
  showSubNavMenui,
  setPage,
  setStatus,
} = slice.actions;

export const reducer = slice.reducer;

export default slice;
